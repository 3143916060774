<template>
    <div id="bot">
        <div class="tabela-bot">
            <!--botão para abrir a modal-->
            <div class="col-12 novo-bot" v-if="!['company-agent'].includes($store.getters.user.group.key)">
                <v-btn class="primary-button" raised @click="criarBot">
                    <i class="fas fa-robot" aria-hidden="true"></i>
                    Novo Bot
                </v-btn>
            </div>
            <!--tabela que ira conter os bots e seus dados-->
            <CustomTable
                v-if="headers != ''"
                :action="'getBots'"
                :getter="$store.getters.bot"
                :headers="headers"
                :search="true"
                :title="'Bots'"
                :icone="'fas fa-robot'"
                :pagination="true"
                :filters="filtros"
                ref="tabela"
            >
				<template v-slot:companies="{ item }" >
					<div v-if="item.companies">
						<span v-for="(company, i) in item.companies" :key="i">
							{{company.name}}
							{{((item.companies.length - 1) > i) ? ', ' : ''}}
						</span>
					</div>
				</template>
                <!--botão para ativação da modal presente na tabela-->
                <template v-slot:acoes="{ item }">
                    <v-btn class="primary-button" raised small @click="editarBot(item.uuid)">
                        <i class="fas fa-cog"></i>
                        Editar
                    </v-btn>
                </template>            
            </CustomTable>
            <!--modal para cadastro e edição dos bots-->
            <v-dialog v-model="dialog_bot" persistent max-width="1200px">
                <v-card>
                    <v-card-title>
                        <span v-if="dados.uuid" class="headline">Editar Bot da Empresa</span>
                        <span v-else class="headline">Novo Bot da Empresa</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-stepper v-model="step">
                                <v-stepper-header>
                                    <v-stepper-step
                                        :editable="dados.uuid ? true : false"
                                        :complete="step > 1"
                                        step="1"
                                    >
                                        Seu Bot
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step
                                        :editable="dados.uuid ? true : false"
                                        :complete="step > 2"
                                        step="2"
                                    >
                                        Mensagens Iniciais 
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                </v-stepper-header>
                                <v-stepper-items>
                                    <v-stepper-content step="1">
                                        <v-form ref="form_dados_um">
                                            <h2 class="text-center">Seu Bot</h2>
                                            <p></p>
                                            <p class="text-center">Bem vindo as configurações do Bot da sua Empresa.</p>
                                            <p class="text-center">Aqui pode-se edita-lo para realizar as cobranças da melhor forma possível</p>
                                            <p class="text-center">Configure os dados da forma que lhe agradar.</p>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6" v-if="!['company-agent'].includes($store.getters.user.group.key)">
                                                            <v-text-field
                                                                :rules="[v => !!v || 'Campo Nome obrigatório']"
                                                                v-model="dados.name"
                                                                label="Nome do Bot"
                                                                placeholder="Nome do Bot"
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            />
                                                        </div>
                                                        <div class="col-6" v-if="!['company-agent'].includes($store.getters.user.group.key)">
                                                            <v-select
                                                                :rules="[v => !!v || 'Campo Empresa obrigatório']"
                                                                :items="$store.getters.companies"
                                                                item-text="name"
                                                                item-value="uuid"
                                                                v-model="dados.empresa"
                                                                label="Empresa do Bot"
                                                                placeholder="Empresa do Bot"
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            />
                                                        </div>
                                                        <div class="col-6" v-if="!['company-agent'].includes($store.getters.user.group.key)">
                                                            <v-select
                                                                :rules="[v => !!v || 'Campo Flow obrigatório']"
                                                                :items="flows"
                                                                item-text="name"
                                                                item-value="uuid"
                                                                v-model="dados.flow"
                                                                label="Flow Abrangido pelo Bot"
                                                                placeholder="Flow Abrangido pelo Bot"
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                                @change="changeFlow()"
                                                            />
                                                        </div>
                                                        <div class="col-6"  v-if=" !['company-agent'].includes($store.getters.user.group.key)">
                                                            <v-text-field
                                                                :disabled="this.dados.empresa == ''"
                                                                v-model="dados.data.department_id_okwhats"
                                                                label="Departamento Responsável pelo Bot"
                                                                placeholder="Departamento Responsável pelo Bot"
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="closeBot">
                                                                Cancelar
                                                            </v-btn>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="row justify-end">
                                                                <div class="col-12 col-md-3">
                                                                    <v-btn class="primary-button" small @click="proximaPagina">
                                                                        Próximo
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-form>
                                    </v-stepper-content>
                                    <v-stepper-content step="2">
                                        <v-form ref="form_dados_dois">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6" v-if="verificaByFlow([dados.flow],flows,'flow_bulk_1') == true">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Mensagem Conhece Usuário Obrigatório.']"
                                                                v-model="dados.data.message_know_user"
                                                                label="Mensagem Confirmação Conhece o Usuário"
                                                                placeholder="Mensagem Confirmação Conhece o Usuário"
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            />
                                                        </div>
                                                        <div class="col-6">
                                                            <v-textarea
                                                                :rules="[v => !!v || 'Campo Mensagem Transferir Atendimento Obrigatório.']"
                                                                v-model="dados.data.mensagem_transferir_atendimento"
                                                                label="Mensagem de Transferência de atendimento"
                                                                placeholder="Mensagem de Transferência de atendimento"
                                                                background-color="white"
                                                                hide-details
                                                                outlined
                                                            />
                                                        </div>
                                                        <!--div para mensagens personalizadas conforme o dia-->
                                                        <div class="col-12">
                                                            <h2>Mensagem Inicial Personalizada</h2>
                                                            <div class="dias" v-for="(dia, i) in dados.data.message_template_initial" :key="i">
                                                                <div class="inputs">
                                                                    <div class="row">
                                                                        <div class="col-md-4 col-12">
                                                                            <v-select
                                                                                :rules="[v => (!!v || v == 0) || 'Campo Dia(s) obrigatório']"
                                                                                :items="dias_semana"
                                                                                v-model="dados.data.message_template_initial[i].week_days"
                                                                                item-text="texto"
                                                                                item-value="dia"
                                                                                label="Dia(s) da semana"
                                                                                placeholder="Dia(s) da semana"
                                                                                background-color="white"
                                                                                hide-details
                                                                                outlined
                                                                                multiple
                                                                            />
                                                                        </div>
                                                                        <div class="col-8">
                                                                            <v-text-field
                                                                                :rules="[v => !!v || 'Campo Primeira Mensagem obrigatório']"
                                                                                v-model="dados.data.message_template_initial[i].text"
                                                                                label="Primeira Mensagem Enviada"
                                                                                placeholder="Primeira Mensagem Enviada"
                                                                                background-color="white"
                                                                                hide-details
                                                                                outlined
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <v-btn v-if="dados.data.message_template_initial.length > 1" type="button" @click="removeDia(i)" color="terciary-button" raised class="remover-dia">
                                                                    <i class="fas fa-minus"></i>
                                                                </v-btn>
                                                            </div>
                                                            <v-btn class="primary-button" raised @click="addDia">
                                                                <i style="margin-right: 10px" class="fas fa-plus"></i>
                                                                Adicionar Dia
                                                            </v-btn>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px; margin-right: 10px;" @click="closeBot">
                                                                Cancelar
                                                            </v-btn>
                                                            <v-btn @click="step = 1">
                                                                Voltar
                                                            </v-btn>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="row justify-end">
                                                                <div class="col-3">
                                                                    <v-btn class="primary-button" small @click="enviarBot">
                                                                        Salvar
                                                                    </v-btn>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-form>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </v-container>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
            <Loader v-if="loading"/>
        </div>
    </div>
</template>
<!--funções para trazer e encaminhar os dados dos bots dos fúncionarios-->
<script>
    // importa o store
    import store from '@/store'
    // importa o componente de DialogMensagem
    import DialogMensagem from '@/components/DialogMensagem.vue'
    // importa o componente de Loader
    import Loader from '@/components/Loader.vue'
    // importa o componente de CustomTable
    import CustomTable from '@/components/CustomTable.vue'

    // exporta o componente
    export default {
        // nome do componente
        name: 'bots',
        // componente filhos
        components: {
            // componente de DialogMensagem
            DialogMensagem,
            // componente de CustomTable
            CustomTable,
            // componente de Loader
            Loader,
        },
        // dados do componente
        data: () => ({
            // variável para mostrar a modal para editar/criar um bot
            dialog_bot: false,
            // variável para mostrar a modal de informação
            dialog_resposta: false,
            // variável para a mensagem de resposta
            resposta: {},
            // variável para o loading
            loading: false,
            // variável para os flows
            flows: [],
            // variável para às páginas das modais
            step: 1,
            // variável para criar/editar um bot
            dados: {
                uuid: '',
                name: '',
                empresa: '',
                flow: '',
                data: {
                    department_id_okwhats: '',
                    external_token: '',
                    external_user: '',
                    type: '',
                    message_confirm_user: '',
                    message_know_user: '',
                    text_message_talk_to_attendant: '',
                    mensagem_transferir_atendimento: '',
                    message_template_initial: [{
                        week_days: '',
                        text: '',
                    }],
                },
            },
            // variável para os dias da semana
            dias_semana: [
                {
                    dia: 1,
                    texto: 'Segunda-feira',
                },
                {
                    dia: 2,
                    texto: 'Terça-feira',
                },
                {
                    dia: 3,
                    texto: 'Quarta-feira',
                },
                {
                    dia: 4,
                    texto: 'Quinta-feira',
                },
                {
                    dia: 5,
                    texto: 'Sexta-feira',
                },
                {
                    dia: 6,
                    texto: 'Sábado',
                },
                {
                    dia: 7,
                    texto: 'Domingo',
                },
            ],
            // variável para o cabeçalho da tabela
            headers: [
                {
                    value: 'uuid',
                    text: 'Código',
                    sortable: true,
                },
                {
                    value: 'name',
                    text: 'Nome',
                    sortable: true,
                },
                {
                    value: 'companies.name',
                    text: 'Empresa',
                    sortable: true,
                },
                {
                    value: 'data.department_id_okwhats',
                    text: 'Departamento Whats',
                    sortable: true,
                },
                {
                    value: 'flow.name',
                    text: 'Flow',
                    sortable: true,
                },
                {
                    value: 'acoes',
                    text: 'Ações',
                    sortable: false,
                },
            ],
            // variável para os filtros da tabela
            filtros: {
                perPage: 20,
            },
        }),
        // funções deste componente
        methods: {
            // função para puxar so flows do back
            async getFlows(){
                // coloca o componente como loading
                this.loading = true
                // faz um dispatch para uma action no vuex para pegar os flows
                var resp = await store.dispatch('getFlows')
                // caso o status da resposta seja 200 (deu certo)
                if(resp.status == 200){
                    // atribui as opções de flows
                    this.flows = resp.data
                // caso tenha dado algum erro
                }else{
                    // atribui o título da mensagem
                    this.resposta.titulo = await 'Algo deu errado!'
                    // atribui o corpo da mensagem
                    this.resposta.mensagem = await resp.data.message || resp.data.error
                    // mostra a mensagem
                    this.dialog_resposta = true
                }
                // retira o loading do componente
                this.loading = false
            },
            // função para abrir a modal para criar um novo bot
            async criarBot(){
                // aciona a função para puxar os flows
                this.getFlows()
                // volta a modal para a primeira pagina
                this.step = 1,
                // abre a modal
                this.dialog_bot = true
            },
            // função para enviar o bot da empresa
            async enviarBot(){
                let resp = {}
                // caso os campos do formulário estejam válidos
                if(this.$refs.form_dados_dois.validate()){
                    // coloca o componente como loading
                    this.loading = await true
                    // caso já exista um bot
                    if(this.dados.uuid){
                        // armazena os dados para realizar a atualização
                        let date_update = await {
                            dados_bot: this.dados,
                            uuid: this.dados.uuid
                        }
                        // rota para atualização da agenda
                        resp = await store.dispatch('putBot', date_update)
                    }else{
                        //  rota para a criação da agenda
                        resp = await store.dispatch('postBot', this.dados)
                    }
                    // caso algo tenha dado errado
                    if(resp.status != 200 && resp.status != 201){
                        // atribui o título da mensagem
                        this.resposta.titulo = await 'Algo deu errado!'
                        // atribui o corpo da mensagem
                        this.resposta.mensagemm = await resp.data.message || resp.data.error
                        // mostra a mensagem 
                        this.dialog_resposta = true
                    }else{
                        // atribui o título da mensagem
                        this.resposta.titulo = await 'Bot ' + (this.dados.uuid ? 'editado!' : 'cadastrado!')
                        // atribui o corpo da mensagem
                        this.resposta.mensagem = await 'Bot ' + (this.dados.uuid ? 'editado' : 'cadastrado') + ' com sucesso!'
                        // fecha a modal de criação/edição de bots
                        this.closeBot()
                        // mostra a mensagem
                        this.dialog_resposta = true
                    }
                    // retira o loading do componente
                    this.loading = false
                    // atualiza a tabela
                    this.$refs.tabela.init()
                }
            },
            // função para abrir uma modal para editar um bot
            async editarBot(uuid){
                // coloca o componente como loading
                this.loading = true
                // faz um dispatch para uma action no vuex para pegar um bot passando o uuid
                var resp = await store.dispatch('getBot', uuid)
                // caso o status da resposta seja 200 (deu certo)
                if(resp.status == 200){
                    // atribui os dados do bot vindos do back à variável local
                    this.dados = {
                        uuid: resp.data.bot.uuid,
                        name: resp.data.bot.name,
                        empresa: resp.data.bot.company_uuid,
                        flow: resp.data.bot.flow_uuid,
                        data: resp.data.bot.data,
                    }
                    // atribui o tipo do bot
                    this.criarBot()
                }else{
                    // atribui o título da mensagem
                    this.resposta.titulo = await 'Algo deu errado!'
                    // atribui o corpo da mensagem
                    this.resposta.mensagem = await resp.data.message || resp.data.error
                    // mostra a mensagem
                    this.dialog_resposta = true
                }
                // retira o loading do componente
                this.loading = false
            },
            // função chamada quando o usuário escolhe um flow
            changeFlow(){
                // verificamos se tem algum flow selecionado
                if(this.dados.flow != ''){
                    // buscamos os índice do flow que foi selecionado
                    let index = this.flows.findIndex(flow => flow.uuid == this.dados.flow)
                    // atribuimos as mensagens de exemplo dele
                    let mensagens = this.flows[index].data_exemplo
                    // repassamos as mensagens para os campos
                    this.dados.data.message_confirm_user = mensagens.message_confirm_user ?? ''
                    this.dados.data.message_know_user = mensagens.message_know_user ?? ''
                    this.dados.data.text_message_talk_to_attendant = mensagens.text_message_talk_to_attendant ?? ''
                    this.dados.data.mensagem_transferir_atendimento = mensagens.mensagem_transferir_atendimento ?? ''
                    // caso tenha mensagem inicial personalizada
                    if(mensagens.message_template_initial){
                        // atribui os dados
                        this.dados.data.message_template_initial[0].week_days = mensagens.message_template_initial[0].week_days
                        this.dados.data.message_template_initial[0].text = mensagens.message_template_initial[0].text
                    }
                }
            },
            // função para fazer verificações de qual flow foi selecionado
            verificaByFlow(flows, flows_base, type){
                // variável para marcar se é o flow buscado
                let true_false = false
                // percorre os flows
                for(let i = 0; i < flows_base.length; i++){
                    // atribui o flow da vez
                    const flow = flows_base[i]
                    // faz a verifição para ver se é o flow que buscamos
                    if(flows.includes(flow.uuid) && flow.key == type){
                        // caso seja altera a variável
                        true_false = true
                    }
                }
                // retorna se é o flow buscado ou não
                return true_false
            },
            // função para fechar a modal de cadastro/edição do bot
            async closeBot(){
                // fecha a modal
                this.dialog_bot = false
                // limpa os dados locais do bot
                this.dados = {
                    uuid: '',
                    name: '',
                    empresa: '',
                    flow: '',
                    data: {
                        department_id_okwhats: '',
                        message_confirm_user: '',
                        message_know_user: '',
                        text_message_talk_to_attendant: '',
                        mensagem_transferir_atendimento: '',
                        message_template_initial: [{
                            week_days: '',
                            text: '',
                        }],
                    }
                }
            },
            // função para mudar de página na modal
            async proximaPagina(){
                if(this.step == 1){
                    if(this.$refs.form_dados_um.validate()){

                        this.step = 2
                    }
                }
            },
            // função para abrir campos extras de adição de dias da semana
            addDia(){
                this.dados.data.message_template_initial.push({
                    week_days: '',
                    text: '',
                })
            },
            // função para remover campos extras de dias da semana
            removeDia(index){
                if(index > -1){
                    this.dados.data.message_template_initial.splice(index, 1)
                }
            },
        }
    }
</script>
<!--scss do front-->
<style lang="scss">
    #bot{
        display: flex;
        max-height: 100%;
        padding: 24px;
        flex-wrap: wrap;
        .tabela-bot{
            width: 100%;
            background-color: #fff;
            border-radius: 10px;
            .novo-bot{
                display: flex;
                justify-content: flex-end;
                padding-bottom: 0;
                button{
                    i, svg{
                        margin-right: 10px;
                    }
                    text-transform: capitalize;
                }
            }
        }
    }
    .dias{
        display: flex;
        flex: 1 1 auto;
        .inputs{
            display: flex;
            flex-wrap: wrap;
            flex: 1 1 auto;
        }
        .remover-dia{
            min-width: inherit;
            margin-top: 20px;
            margin-left: 13px;
        }
    }
</style>